/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import { Link } from "react-router-dom";

import './Navbar.scss'
import { images } from '../../assets'

const Navbar = () => {

  return (
    <div className="Navbar">
      <div className="nav-container">
        <div className="logo"><Link to="/"><img src={images.logo}></img></Link></div>
        <nav className="app_navbar" id="app_navbar">
          <ul className="links">
            <li><Link to="/"> - 🏚️ Home - </Link></li>
            <li><Link to="/about"> - 🧑🏽‍🦱 About Me - </Link></li>
            <li><Link to="/work"> - 💼 Projects - </Link></li>
            <li><Link to="/contact"> - 🗨 Contact - </Link></li>
          </ul>
        </nav>
      </div>

    </div>
  )
}

export default Navbar