/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react'
import './Contact.scss'


const Contact = () => {
  return (
    <div className="Contact">
      <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSe9QfIiuOx0rDzKBYfqfF2RbL4AKWQRLqtccB9PlldGbIKhUQ/viewform?embedded=true" width="1300" height="1000" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  )
}

export default Contact