import createClient from '@sanity/client';
import createImageUrlBuilder from '@sanity/image-url'

export const  sanityClient = createClient({
    projectId: process.env.REACT_APP__SANITY_PROJECT_ID,
    dataset: 'production',
    apiVersion: '2022-02-01',
    useCdn: true,
    token: process.env.REACT_APP__SANITY_TOKEN,
});

// Code to write when dealing with images using Sanity
export const urlFor = (source) => createImageUrlBuilder(sanityClient).image(source);