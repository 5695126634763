import React, { useState, useEffect } from "react";
import { PortableText } from "@portabletext/react";

import "./About.scss";
import { urlFor, sanityClient } from "../../client";

const About = () => {
  const [about_us_data, set_about_us_data] = useState([]);
  const [cert_data, set_cert_data] = useState([]);
  const [subscriptions, set_subscriptions] = useState([]);

  useEffect(() => {
    const query = '*[_type == "about_us"]';

    sanityClient
      .fetch(query)
      .then((about_response) => {
        set_about_us_data(about_response)
        set_subscriptions(about_response[0]['subscriptions'])
        set_cert_data(about_response[0]['certifications'])
      })
  }, []);

  return (
    <div className="About">
      <div className="picture-box">
        {about_us_data.map((profile, index) => (
          <img
            src={urlFor(profile.imageUrl)}
            alt="umang-patel"
            className="img-profile"
            key={index}
          />
        ))}
        <p>
          <b>Umangkumar Patel</b>, which means{" "}
          <i>"to bring joy and hapiness"</i>
        </p>
      </div>
      <div className="about-box">
        <div>
          <h3>About</h3>
          <i id="caption">
            Little about myself, that you may like to read to get to know me
            better
          </i>
          {about_us_data.map((data, index) => (
            <PortableText value={data.description} key={index} />
          ))}
        </div>
        <div>
          <h3>Tools and Tech</h3>
          <i id="caption">
            What technologies I use for most of my projects (+more that I won't
            reveal HA!)
          </i>
          <p>VS Code, Github (under construction)</p>
        </div>
        <div>
          <h3>Certifications</h3>
          <i id="caption">
            Featured certifications that I like to display here ;)
          </i>
          <br />
          {cert_data.map((cert, index) => (
            <a
              href={cert.link}
              target="_blank"
              key={index + "link"}
              rel="noreferrer"
            >
              <img
                src={urlFor(cert.imageUrl)}
                alt={cert.certification}
                className="img-profile"
                key={index}
              />
            </a>
          ))}
        </div>
        <div>
          <h3>Subscriptions</h3>
          <i id="caption">
            My favorite go-to places for staying up-to-date and never stop
            learning!
          </i><br/>
          {subscriptions.map((sub, index) => (
            <a
              href={sub.link}
              target="_blank"
              key={index + "link"}
              rel="noreferrer"
            >
              <img
                src={urlFor(sub.imageUrl)}
                alt={sub.subscription}
                className="img-sub"
                key={index}
              />
            </a>
          ))}
        </div>
        <div>
          <h3>Sports</h3>
          <i id="caption">Maybe we should compete!</i>
          {about_us_data.map((sports, index) => (
            <PortableText value={sports.sports} key={index} />
          ))}
        </div>
        <div>
          <h3>Photos</h3>
          <p>-</p>
        </div>
      </div>
    </div>
  );
};

export default About;
