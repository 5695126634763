import './NotFound.scss'


export const NotFound = () => {
    return (
        <div>
            <h1>Page Not Found</h1>
        </div>
    )
}
export default NotFound;