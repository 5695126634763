import { React, useState, useEffect } from 'react'
import './Welcome.scss'
import Typewriter from 'typewriter-effect';
import { images } from '../../assets';
import {sanityClient} from "../../client";

const Welcome = () => {
  const [file, set_file] = useState();

  useEffect(() => {
    const query = '*[_type == "resume" && version__id=="Latest"]';
    sanityClient
      .fetch(query)
      .then((resume_data) => {
        const [_file, id, extension] = resume_data[0]['resume']['asset']['_ref'].split('-');

        var file_url = `https://cdn.sanity.io/files/${process.env.REACT_APP__SANITY_PROJECT_ID}/production/${id}.${extension}`
        console.log(file_url)
        set_file(file_url)
        // set_file(resume_data[0]['resume']['asset'])
      })
    }, []);
  return (
    <div className="Welcome">
      <div>
        <div>
          <div className="hello">Hello 👋🏼<br />I'm Umang Patel!</div>
          <span id='and'>and</span>
          <div className="iam-typewriter">I am a 👇🏼
            <Typewriter
              options={{
                autoStart: true,
                loop: true,
              }}
              onInit={(typewriter) => {
                typewriter.typeString('Software Developer')
                  .pauseFor(50)
                  .deleteAll()
                  .typeString('Programmer')
                  .pauseFor(50)
                  .deleteAll()
                  .typeString('Web Developer')
                  .pauseFor(50)
                  .deleteAll()
                  .start();
              }}
            />
          </div>
        </div>
        <img src={images.profile} alt="profile-main" id="profile-main"/>
      </div>
      <a href={file} target="_blank" rel="noreferrer"><button className="download-cv">Download CV</button></a>
      <div className="social">
        <a href="https://www.linkedin.com/in/ukpatel/" target="_blank" rel="noreferrer"><img src={images.linkedin} alt="linkedin-umang"></img></a>
        <a href="https://github.com/ukpatell" target="_blank" rel="noreferrer" ><img src={images.github} alt="linkedin-umang"></img></a>
      </div>
    </div>
  )
}

export default Welcome