import React from 'react'
import { Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { Navbar, Welcome, About, Work,Contact, NotFound} from './components'
import './App.css';

const App = () => {
  return (
    <>
    <Router>
      <Navbar/>
      <div className="App">
        <Routes>
          <Route index element={<Welcome/>}/>
          <Route exact path="/about" element={<About/>}/>
          <Route exact path="/work" element={<Work/>}/>
          <Route exact path="/contact" element={<Contact/>}/>
          <Route path="*" element={<NotFound/>}/>
        </Routes>
      </div>
    </Router>
    </>
  )
}

export default App
